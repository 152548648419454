import React, { useEffect, useState } from 'react'
import type { EmblaCarouselType } from 'embla-carousel'
import { twcx } from 'helpers'


type DotsProps = {
  emblaApi: EmblaCarouselType
  className?: string
  dotClassName?: string
  activeDotClassName?: string
}

const Dots: React.FC<DotsProps> = (props) => {
  const { emblaApi, className, dotClassName, activeDotClassName } = props

  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [ scrollSnaps, setScrollSnaps ] = useState<number[]>([])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    const onInit = (emblaApi: EmblaCarouselType) => {
      setScrollSnaps(emblaApi.scrollSnapList())
    }

    const onSelect = (emblaApi: EmblaCarouselType) => {
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)

    return () => {
      if (!emblaApi) {
        return
      }

      emblaApi.off('reInit', onInit).off('reInit', onSelect).off('select', onSelect)
    }
  }, [ emblaApi ])

  return (
    <div className={twcx(className)} role={scrollSnaps.length ? 'tablist' : 'presentation'}>
      {scrollSnaps.map((_, index) => (
        <button
          key={index}
          className={twcx(dotClassName, index === selectedIndex && activeDotClassName)}
          role="tab"
          type="button"
          data-index={index}
          aria-label={`Page ${index + 1}`}
          onClick={() => {
            if (!emblaApi) {
              return
            }
            emblaApi.scrollTo(index)
          }}
        />
      ))}
    </div>
  )
}


export default Dots
